import { useQuery } from "@apollo/client"
import React, {useState } from "react"
import { PRICING_PAGE_URL,PRICING_SUMMARY_PAGE } from "../../components/graphql"
import Layout from "../../components/layout"
import Login from "../../components/login"
import "../../assets/css/pricingStyle.css"
import navImg from "../../assets/images/arrow-forward.png"
import { Link } from "gatsby"
import jQuery from "jquery"
import ReactMarkdown from "react-markdown"
import checkmark from "../../assets/images/checked-checkbox.png"
import parse from "html-react-parser"

function PricingIndexPage() {
  const pricingData = useQuery(PRICING_PAGE_URL)
  const pricingSummaryData = useQuery(PRICING_SUMMARY_PAGE)
  const [open, setOpen] = useState(false)
  const [login, setLogin] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [username, setUsername] = useState(undefined)
  const [userId, setUserID] = useState(undefined)
  const [phoneError, setphoneError] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [categoryServiceIndex, setCategoryServiceIndex] = useState(0)
  const [categoryType, setCategoryType] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState("USD")
  const [cartItems, setCartItems] = useState([])
  const [bundleMode, setBundleMode] = useState(false)
  const [cartBundle, setCartBundle] = useState()
  const [categoryIndex, setCategoryIndex] = useState(0)
  const [serviceIndex, setServiceIndex] = useState(0)

  var pricingPage;
  var summaryData;
  if (pricingData) {
    pricingPage = pricingData?.data?.pricingPage
  }
  if(pricingSummaryData){
    summaryData = pricingSummaryData?.data?.pricingPage
  }
  React.useEffect(() => {
    const tabs = document.querySelectorAll("[data-tab-value]")
    const tabInfos = document.querySelectorAll("[data-tab-info]")
    tabs.forEach((tab, index) => {
      if (index == categoryIndex) {
        tab.classList.add("active")
        document.querySelector(`#tab_1`).classList.add("active")
      } else {
        tab.classList.remove("active")
      }
    });

    tabs.forEach(tab => {
      tab.addEventListener("click", () => {
        console.log("clickercalled", tabs, tabInfos)
        tabs.forEach(tabremove => {
          tabremove.classList.remove("active")
        })
        tab.classList.add("active")
        console.log("datasetvalue", tab.dataset.tabValue)
        const target = document.querySelector(tab.dataset.tabValue)

        tabInfos.forEach(tabInfo => {
          tabInfo.classList.remove("active")
        })
        target.classList.add("active")
      })
    });

    jQuery(function ($) {
      var tabwrapWidth = $(".tabs-wrapper").outerWidth()
      var totalWidth = 0

      jQuery("ul li").each(function () {
        totalWidth += jQuery(this).outerWidth()
      })
      if (totalWidth > tabwrapWidth) {
        $(".scroller-btn").removeClass("inactive")
      } else {
        $(".scroller-btn").addClass("inactive")
      }

      if ($("#scroller").scrollLeft() == 0) {
        $(".scroller-btn.left").addClass("inactive")
      } else {
        $(".scroller-btn.left").removeClass("inactive")
      }
      var liWidth = $("#scroller li").outerWidth()
      var liCount = $("#scroller li").length
      var scrollWidth = liWidth * liCount

      $(".right").on("click", function () {
        $(".nav-tabs").animate({ scrollLeft: "+=200px" }, 300)
        console.log($("#scroller").scrollLeft() + " px")
      })

      $(".left").on("click", function () {
        $(".nav-tabs").animate({ scrollLeft: "-=200px" }, 300)
      })
      scrollerHide()

      function scrollerHide() {
        var scrollLeftPrev = 0
        $("#scroller").on("scroll", function () {
          var $elem = $("#scroller")
          var newScrollLeft = $elem.scrollLeft(),
            width = $elem.outerWidth(),
            scrollWidth = $elem.get(0).scrollWidth
          if (scrollWidth - newScrollLeft == width) {
            $(".right.scroller-btn").addClass("inactive")
          } else {
            $(".right.scroller-btn").removeClass("inactive")
          }
          if (newScrollLeft === 0) {
            $(".left.scroller-btn").addClass("inactive")
          } else {
            $(".left.scroller-btn").removeClass("inactive")
          }
          scrollLeftPrev = newScrollLeft
        })
      }
    });
  });
  
  React.useEffect(() => {
    if (cartItems.length == 0) {
      setSelectedCurrency("USD")
    }
  }, [cartItems]);

  const currencyPrinter = priceList => {
    var current = priceList?.filter(
      e => e.currencyType?.toString()?.toUpperCase() == selectedCurrency
    )[0]

    return (
      <div>
        {current?.servicePrice == 0 ? "FREE" : current?.servicePrice ?? "FREE"}
        {current?.servicePrice == 0 ? "" : current?.currencySymbol}
      </div>
    )
  }

  const addToCart = product => {
    console.log("cart", product)
    setCartItems([
      ...new Map(
        [...cartItems, product].map(item => [item["id"], item])
      ).values(),
    ])
  }

  const removeFromCart = product => {
    setCartItems(cartItems.filter(e => e.id !== product.id))
  }

  const changeBundleMode = mode => {
    setCartItems([])
    setBundleMode(mode)
  }

  const changeServices = (service, index) => {
    setServiceIndex(index)
    if (!bundleMode) {
      setCartItems([])
    }
  }

  const changeCategoryType = (isBundleProducts, index) => {
    setCategoryType(isBundleProducts)
    setCategoryIndex(index)
    setCartItems([])
    setCartBundle()
    setBundleMode(false)
  }

  const selectBundle = bundle => {
    setCartBundle(bundle)
  }

  const proceedCart = items => {
    sessionStorage.setItem(
      "CARTITEMS",
      JSON.stringify({
        cartItem: items,
        currencyMode: selectedCurrency,
        isBundleProducts: categoryType,
        isCreatedBundle:bundleMode,
        bundleDiscountValue:summaryData?.bundleDiscountPrice
      })
    )
  }

  return (
    <Layout setTitle="Pricing">
      <Login
        open={open}
        setOpen={setOpen}
        login={login}
        setLogin={setLogin}
        setUsername={setUsername}
        setUserID={setUserID}
        forgotPassword={forgotPassword}
        setForgotPassword={setForgotPassword}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        successMessage={successMessage}
        setSuccessMessage={setSuccessMessage}
        phoneError={phoneError}
        setphoneError={setphoneError}
      />
      <div className="main-content pricingsection">
        {pricingPage && (
          <div className="pricing-section fullwidth-bg">
            <div className="container">
              <div className="price-section">
                <div className="pricing-container">
                  <div className="title blue pricingTitle">
                    {pricingPage?.title}
                  </div>
                  <div className="pricing-description">
                    {pricingPage?.description}
                  </div>
                </div>
                <div className="pricing-image image">
                  <img
                    src={
                      pricingPage?.image?.url
                        ? process.env.BACKEND_URL + pricingPage?.image?.url
                        : ""
                    }
                    alt="imageSection"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="container price-tabs">
          <div class="content">
            <div class="tabs">
              <div className="tab-list">
                {pricingPage?.pricingCategoryContent?.map((category, index) => (
                  <span
                    data-tab-value={`#tab_${index + 1}`}
                    onClick={() =>
                      changeCategoryType(category.isBundleProducts, index)
                    }
                  >
                    {category.title}
                  </span>
                ))}
              </div>
            </div>
            <div class="tab-content-p">
              {pricingPage?.pricingCategoryContent?.map((category, index) => (
                <div
                  kdy={index}
                  class="tabs__tab"
                  id={`tab_${index + 1}`}
                  data-tab-info
                >
                  {categoryType == false && (
                    <div class="wrapper">
                      <div class="tabs-wrapper">
                        <div className="tabs-slider">
                          <span class="left scroller-btn">
                            <img src={navImg} alt="" />
                          </span>
                          <ul id="scroller" class="nav nav-tabs">
                            {category?.pricingCategoryServices?.map(
                              (service, index) => (
                                <li
                                  class={`menu_tab_${index + 1} ${
                                    serviceIndex == index ? "active" : ""
                                  }`}
                                  onClick={() => changeServices(service, index)}
                                >
                                  <a
                                    data-toggle="tab"
                                    id={`menu_tab_${index + 1}`}
                                  >
                                    {service?.title}
                                  </a>
                                </li>
                              )
                            )}
                          </ul>
                          <span id="right" class="right scroller-btn">
                            <img src={navImg} alt="" />
                          </span>
                        </div>
                        <div
                          className={`createBundleButton ${
                            bundleMode ? "active" : ""
                          }`}
                          onClick={() => changeBundleMode(!bundleMode)}
                        >
                          <div className="b-btn">
                            {pricingPage?.createBundleButton} {' '}
                            <img src={checkmark} alt="checkmark" />
                          </div>
                        </div>
                      </div>

                      {pricingPage?.pricingCategoryContent?.map(
                        (category, index) => (
                          <div key={index} class="tab-content">
                            {category?.pricingCategoryServices?.length > 0
                              ? category?.pricingCategoryServices
                                  ?.filter((e, index) => serviceIndex == index)
                                  ?.map((service, serviceIndex) => (
                                    <div
                                      key={index}
                                      className="tab-pane fade active show"
                                    >
                                      {service?.serviceProducts.map(
                                        (product, index) => (
                                          <div
                                            key={index}
                                            className={`productCard ${
                                              cartItems.includes(product)
                                                ? "active"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              cartItems.includes(product)
                                                ? removeFromCart(product)
                                                : addToCart(product)
                                            }
                                          >
                                            <div className="productImage">
                                              <img
                                                src={
                                                  product?.serviceImage?.url
                                                    ? process.env.BACKEND_URL +
                                                      product?.serviceImage?.url
                                                    : ""
                                                }
                                                alt="imageSection"
                                              />
                                            </div>
                                            <p>{product?.serviceTitle}</p>
                                            <div className="categoryPriceContainer">
                                              {currencyPrinter(
                                                product?.servicePrice
                                              )}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ))
                              : null}
                          </div>
                        )
                      )}
                      <div className="proceed-btn">
                        {cartItems?.length > 0 && (
                          <div className="cta-primary">
                            <Link
                              to="/pricing/summary/"
                              onClick={() => proceedCart(cartItems)}
                              className="proceedButton"
                            >
                              {!bundleMode?"Proceed":"Create Bundle"}
                            </Link>
                          </div>
                        )}
                      </div>
                      <div className="pricingFooter">
                        <div className="currencySelector">
                          <div>
                          
                            <div className="footerContent">
                              <ReactMarkdown
                                source={pricingPage?.individualPricing}
                              />
                            </div>
                            <div className="currencies">
                              {console.log(
                                "currencylist",
                                category?.pricingCategoryServices
                              )}
                              {cartItems[0]?.servicePrice?.map(
                                (service, index) => (
                                  <div
                                    className={`currency ${
                                      selectedCurrency == service.currencyType
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      setSelectedCurrency(service?.currencyType)
                                    }
                                    index={index}
                                  >
                                    {service.currencyType}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              {categoryType == true && (
                <div className="tab-bundles">
                  <div class="wrapper">
                    {pricingPage?.pricingCategoryContent
                      ?.filter(
                        (category, index) => category.isBundleProducts == true
                      )
                      ?.map((category, index) =>
                        category?.pricingCategoryBundle.map((bundle, index) => (
                          <div
                            key={index}
                            className={`bundle ${
                              cartBundle?.id == bundle.id ? "active" : ""
                            }`}
                            onClick={() => selectBundle(bundle)}
                          >
                            <div className="bundleImage">
                              <img
                                src={
                                  bundle?.bundleImage?.url
                                    ? process.env.BACKEND_URL +
                                      bundle?.bundleImage?.url
                                    : ""
                                }
                                alt="imageSection"
                              />
                            </div>
                            <div className="name">{bundle?.bundleName}</div>
                            <div className="desc">
                              {bundle?.bundleDescription}
                            </div>
                            <div className="price">
                              {currencyPrinter(bundle?.bundlePricing)}
                            </div>
                            <div className="bonus">
                                {parse(bundle?.bonusSection)}
                            </div>
                          </div>
                        ))
                      )}
                  </div>
                  <div className="proceed-btn">
                    {cartBundle && (
                      <div className="cta-primary">
                        <Link
                          to="/pricing/summary/"
                          className="proceedButton"
                          onClick={() => proceedCart(cartBundle)}
                        >
                          Proceed
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="pricingFooter">
                    <div className="currencySelector">
                      {categoryType == true && (
                        <div>
                          <div className="footerContent">
                          {parse(pricingPage?.pricingFooterContent)}
                          </div>
                          <div className="currencies">
                            {cartBundle?.bundlePricing?.map((price, index) => (
                              <div
                                className={`currency ${
                                  selectedCurrency == price.currencyType
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  setSelectedCurrency(price?.currencyType)
                                }
                                key={index}
                              >
                                {price?.currencyType}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PricingIndexPage
